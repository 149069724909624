<template>
  <div>
    <NbPageTitle :title="$t('myNotificationsListPage.title')" />

    <section class="mb-4 pb-4 ml-3">
      <NbTablev2
        ref="notificationsTable"
        tableOf="notifications"
        namespace="my_notifications"
        :fields="fields"
        :all-fields="tableFields"
        @reloadFields="fields = $event"
        :filterOptions="filterOptions"
        :optionsWidth="260"
        @total="checkQuery()"
        :show-column-options="false"
      >
        <template #cell(title)="data">
          <span
            :class="
              data.item.is_new
                ? 'font-weight-bold'
                : 'text-dark font-weight-normal'
            "
          >
            {{
              data.item.type == "seller_notifications"
                ? capitalizeWords(
                    sellerNotificationTitles[data.item.title] || "-"
                  )
                : capitalizeWords(
                    userNotificationTitles[data.item.title] || "-"
                  )
            }}
          </span>
        </template>
        <template #cell(body)="data">
          <span
            :class="
              data.item.is_new
                ? 'font-weight-bold'
                : 'text-dark font-weight-normal'
            "
          >
            {{ displayOnlyCharacters(data.item.body || "-", 160) }}
          </span>
        </template>
        <template #cell(created_at)="data">
          {{ data.item.created_at | timeAgo(currentLocale) }}
        </template>
        <template #cell(is_new)="data">
          <span
            :class="
              data.item.is_new
                ? 'font-weight-bold'
                : 'text-dark font-weight-normal'
            "
          >
            {{
              data.item.is_new
                ? $t("myNotificationsPage.fields.isNew")
                : $t("myNotificationsPage.fields.isRead")
            }}
          </span>
        </template>

        <template #cell(actions)="data">
          <NbButton
            :id="`notifi-${data.item.id}`"
            variant="tertiary"
            @click="showNotification(data.item)"
            >{{ $t("myNotificationsListPage.view") }} ></NbButton
          >
        </template>
      </NbTablev2>
    </section>

    <!-- modals -->
    <NbModal
      id="modal-notification"
      modalConfig="modal-dialog-centered"
      @close="resetQuery()"
    >
      <template #header>
        <div class="d-flex mb-4">
          <h5 class="heading-4 mb-1">
            {{
              currentNotification.type == "seller_notifications"
                ? capitalizeWords(
                    sellerNotificationTitles[currentNotification.title] || "-"
                  )
                : capitalizeWords(
                    userNotificationTitles[currentNotification.title] || "-"
                  )
            }}
          </h5>
        </div>
      </template>

      <template #body>
        {{ currentNotification.body }}

        <NbTextInput
          :name="
            $t('myNotificationsPage.reply') + ': (more than 10 characters)'
          "
          id="notification-response"
          v-model="operational_ticket.seller_response"
          class="mt-4"
        />
      </template>

      <template #footer>
        <div class="d-flex justify-content-between">
          <NbButton
            variant="secondary"
            data-dismiss="modal"
            @click="resetQuery()"
          >
            {{ $t("close") }}
          </NbButton>
          <NbButton @click="saveResponse" :disabled="loading">
            {{ $t("myNotificationsPage.reply") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
    <!-- endmodals -->
  </div>
</template>

<script>
import OperationalTicketService from "@/services/OperationalTicketService";
import NotificationService from "@/services/NotificationService";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbModal from "@/components/modal/NbModal.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import moment from "moment";
import { mapState } from "vuex";

const notificationService = new NotificationService();
const operationalTicketService = new OperationalTicketService();

export default {
  name: "NotificationsList",
  components: {
    NbPageTitle,
    NbTablev2,
    NbButton,
    NbModal,
    NbTextInput,
  },
  filters: {
    timeAgo(time, locale = "en") {
      moment.locale(locale);
      return moment(time).fromNow();
    },
  },
  data() {
    return {
      queryControl: true,
      fields: [],
      operational_ticket: {
        seller_notification_id: null,
        seller_response: null,
      },
      currentNotification: {
        id: 0,
        title: "",
        body: "",
        is_new: false,
      },
      sellerNotificationTitles: {},
      userNotificationTitles: {},
      loading: false,
    };
  },
  beforeMount() {
    this.getTitles();
  },
  methods: {
    checkQuery() {
      if (this.$route.query.notificationId && this.queryControl) {
        this.queryControl = false;
        setTimeout(() => {
          const element = document.getElementById(
            `notifi-${this.$route.query.notificationId}`
          );
          element.click();
        }, 100);
        return;
      }
    },
    resetQuery() {
      this.$router.push({ query: {} });
    },
    getTitles() {
      notificationService.getSellerNotificationsTitles().then((response) => {
        this.sellerNotificationTitles = response.data.data;
      });
      notificationService.getUserNotificationsTitles().then((response) => {
        this.userNotificationTitles = response.data.data;
      });
    },
    isRead(read, notification) {
      let finalIds = [];
      if (notification) {
        finalIds.push(notification.id);
      } else {
        finalIds = this.selectedNotifications;
      }
      let notificationToUpdate;
      if (notification.type == "seller_notifications") {
        notificationToUpdate = {
          seller_notification: { is_new: read, ids: finalIds },
        };
      }
      if (notification.type == "user_notifications") {
        notificationToUpdate = {
          user_notification: { is_new: read, ids: finalIds },
        };
      }
      notificationService[`updateNotificationIsRead${notification.type}`](
        notificationToUpdate
      )
        .then(() => {
          this.$refs?.notificationsTable?.getData();
        })
        .catch(() => {
          this.$helpers.toast(
            "Update gone wrong... try again later.",
            "danger"
          );
        });
    },
    submitFilterForm(event) {
      this.filters = {};
      if (event) {
        this.filters = event;
      }
      this.filters.offset = 0;
      const appliedFilters = {};

      Object.keys(this.filters).forEach((filterName) => {
        const filterData = this.filters[filterName];
        if (filterData && filterData !== "null") {
          appliedFilters[filterName] = filterData;
        }
      });

      this.$refs.notificationsTable?.getData();
    },
    displayOnlyCharacters(word, maxChars) {
      if (word.length > maxChars) {
        return word.substring(0, maxChars - 5) + "(...)";
      }
      return word;
    },
    showNotification(notification) {
      if (notification.is_new) {
        this.$root.$emit("reloadSellerNotifications");
        this.isRead(false, notification);
      }
      if (notification.body) {
        this.currentNotification = notification;
        this.operational_ticket.seller_response = "";
        this.$helpers.openModal("modal-notification");
      }
    },
    saveResponse() {
      this.loading = true;
      this.operational_ticket.seller_notification_id =
        this.currentNotification.id;
      operationalTicketService
        .updateOperationalTicket(this.operational_ticket)
        .then(() => {
          this.$helpers.closeModal("modal-notification");
        })
        .catch(() => {
          this.$bvToast.toast("Update gone wrong... try again later.", {
            title: "An error has ocurred",
            solid: true,
            variant: "danger",
            autoHideDelay: 3000,
          });
        })
        .finally(() => (this.loading = false));
    },
    capitalizeWords(string) {
      string = string.replace("_", " ");
      return string.replace(/(?:^|\s)\S/g, (stringChar) => {
        return stringChar.toUpperCase();
      });
    },
  },
  computed: {
    ...mapState({
      currentLocale: (state) => state.accessibility.currentLocale?.lang,
    }),
    tableFields() {
      return [
        { key: "title", label: this.$t("myNotificationsPage.fields.title") },
        { key: "body", label: this.$t("myNotificationsPage.fields.body") },
        {
          key: "created_at",
          label: this.$t("myNotificationsPage.fields.created_at"),
        },
        {
          key: "actions",
          isCustomizable: false,
          label: this.$t("quickActions"),
          class: "right--20 right-sticky",
        },
      ];
    },
    filterOptions() {
      return [
        {
          key: "is_new",
          label: this.$t("myNotificationsListPage.filters.isNew"),
          type: "select",
          options: this.allStatus,
        },
      ];
    },
    allStatus() {
      return [
        { value: null, text: "All" },
        { value: true, text: this.$t("myNotificationsPage.fields.isNew") },
        { value: false, text: this.$t("myNotificationsPage.fields.isRead") },
      ];
    },
  },
};
</script>

<style></style>

import HttpService from "./HttpService";

export default class OrderService {
  constructor() {
    this.httpService = HttpService;
  }

  /**
   * Get operational_ticket
   *
   * @param {*} id
   * @param filters
   * @returns {Promise}
   */
  getOperationalTicket(id = null, filters = {}) {
    return this.httpService.get("/v1/operational_tickets/" + id, {
      params: filters,
    });
  }

  /**
   * Find operational_ticket
   *
   * @param {*} id
   * @param filters
   * @returns {Promise}
   */
  findOperationalTicket(filters = {}) {
    return this.httpService.get("/v1/find_operational_ticket", {
      params: {
        seller_notification_id: filters.id,
      },
    });
  }

  /**
   * Get operational_tickets
   *
   * @param filters
   * @returns {Promise}
   */
  getOperationalTickets(filters = {}) {
    return this.httpService.get("/v1/operational_tickets", {
      params: filters,
    });
  }

  /**
   * Set operational_ticket
   *
   * @param data
   * @returns {Promise}
   */
  setOperationalTicket(data = {}) {
    return this.httpService.post("/v1/operational_tickets", data);
  }

  /**
   * Update operational_ticket
   *
   * @param {*} id
   * @param {*} data
   * @returns {Promise}
   */
  updateOperationalTicket(data = {}) {
    return this.httpService.put("/v1/respond_ticket", data);
  }

  /**
   * Delete operational_ticket
   *
   * @param {*} id
   * @returns {Promise}
   */
  deleteOperationalTicket(id = null) {
    return this.httpService.delete("/v1/operational_tickets/" + id);
  }
}

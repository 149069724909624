var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('NbPageTitle',{attrs:{"title":_vm.$t('myNotificationsListPage.title')}}),_c('section',{staticClass:"mb-4 pb-4 ml-3"},[_c('NbTablev2',{ref:"notificationsTable",attrs:{"tableOf":"notifications","namespace":"my_notifications","fields":_vm.fields,"all-fields":_vm.tableFields,"filterOptions":_vm.filterOptions,"optionsWidth":260,"show-column-options":false},on:{"reloadFields":function($event){_vm.fields = $event},"total":function($event){return _vm.checkQuery()}},scopedSlots:_vm._u([{key:"cell(title)",fn:function(data){return [_c('span',{class:data.item.is_new
              ? 'font-weight-bold'
              : 'text-dark font-weight-normal'},[_vm._v(" "+_vm._s(data.item.type == "seller_notifications" ? _vm.capitalizeWords( _vm.sellerNotificationTitles[data.item.title] || "-" ) : _vm.capitalizeWords( _vm.userNotificationTitles[data.item.title] || "-" ))+" ")])]}},{key:"cell(body)",fn:function(data){return [_c('span',{class:data.item.is_new
              ? 'font-weight-bold'
              : 'text-dark font-weight-normal'},[_vm._v(" "+_vm._s(_vm.displayOnlyCharacters(data.item.body || "-", 160))+" ")])]}},{key:"cell(created_at)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("timeAgo")(data.item.created_at,_vm.currentLocale))+" ")]}},{key:"cell(is_new)",fn:function(data){return [_c('span',{class:data.item.is_new
              ? 'font-weight-bold'
              : 'text-dark font-weight-normal'},[_vm._v(" "+_vm._s(data.item.is_new ? _vm.$t("myNotificationsPage.fields.isNew") : _vm.$t("myNotificationsPage.fields.isRead"))+" ")])]}},{key:"cell(actions)",fn:function(data){return [_c('NbButton',{attrs:{"id":`notifi-${data.item.id}`,"variant":"tertiary"},on:{"click":function($event){return _vm.showNotification(data.item)}}},[_vm._v(_vm._s(_vm.$t("myNotificationsListPage.view"))+" >")])]}}])})],1),_c('NbModal',{attrs:{"id":"modal-notification","modalConfig":"modal-dialog-centered"},on:{"close":function($event){return _vm.resetQuery()}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex mb-4"},[_c('h5',{staticClass:"heading-4 mb-1"},[_vm._v(" "+_vm._s(_vm.currentNotification.type == "seller_notifications" ? _vm.capitalizeWords( _vm.sellerNotificationTitles[_vm.currentNotification.title] || "-" ) : _vm.capitalizeWords( _vm.userNotificationTitles[_vm.currentNotification.title] || "-" ))+" ")])])]},proxy:true},{key:"body",fn:function(){return [_vm._v(" "+_vm._s(_vm.currentNotification.body)+" "),_c('NbTextInput',{staticClass:"mt-4",attrs:{"name":_vm.$t('myNotificationsPage.reply') + ': (more than 10 characters)',"id":"notification-response"},model:{value:(_vm.operational_ticket.seller_response),callback:function ($$v) {_vm.$set(_vm.operational_ticket, "seller_response", $$v)},expression:"operational_ticket.seller_response"}})]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('NbButton',{attrs:{"variant":"secondary","data-dismiss":"modal"},on:{"click":function($event){return _vm.resetQuery()}}},[_vm._v(" "+_vm._s(_vm.$t("close"))+" ")]),_c('NbButton',{attrs:{"disabled":_vm.loading},on:{"click":_vm.saveResponse}},[_vm._v(" "+_vm._s(_vm.$t("myNotificationsPage.reply"))+" ")])],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }